body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sticky_appointment {
  position: sticky;
    width: 100%;
    z-index: 10;
    top: 85px!important;
    padding-bottom: 12px;
}

.sticky_patient {
  position: sticky;
    z-index: 10;
    top: 0px;
    padding-bottom: 2px;
}

.sticky-patient-header {
  position: sticky;
  z-index: 10;
  top: 16rem;
}